var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-membership-policy-container wrapper"},[_c('CRow',{},[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('PAYOUT_POLICY_PAGE_TITLE')))])]),(_vm.isLoading)?_c('CCardBody',[_c('vcl-list')],1):_vm._e(),(!_vm.isLoading)?_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"PAYOUT_POLICY_PAGE_LABEL_COMMISSION","rules":"required|max_value:100|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"5","md":"5"}},[_c('label',[_vm._v(_vm._s(_vm.$t('PAYOUT_POLICY_PAGE_LABEL_COMMISSION')))])]),_c('CCol',{attrs:{"lg":"7","md":"7"}},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":2,"currency":{ suffix: '%' }},model:{value:(_vm.config.proportionShare),callback:function ($$v) {_vm.$set(_vm.config, "proportionShare", $$v)},expression:"config.proportionShare"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"PAYOUT_POLICY_PAGE_LABEL_MAX_LEVEL","rules":("required|min_value:1|max_value:" + _vm.maxLevels)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"5","md":"5"}},[_c('label',[_vm._v(_vm._s(_vm.$t('PAYOUT_POLICY_PAGE_LABEL_MAX_LEVEL')))])]),_c('CCol',{attrs:{"lg":"7","md":"7"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.maxLevels),expression:"config.maxLevels"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.maxLevels)},on:{"change":function($event){return _vm.onChangeMaxLevel()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "maxLevels", $event.target.value)}}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('div',{staticClass:"form-max-rate"},_vm._l((_vm.config.rates),function(rate,index){return _c('ValidationProvider',{key:index,attrs:{"name":((_vm.$t('PAYOUT_POLICY_PAGE_LABEL_LEVEL')) + " " + (index + 1)),"rules":"required|max_value:100|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{key:index,staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"5","md":"5"}},[_c('label',[_vm._v(_vm._s(_vm.$t('PAYOUT_POLICY_PAGE_LABEL_LEVEL'))+" "+_vm._s(index + 1))])]),_c('CCol',{attrs:{"lg":"7","md":"7"}},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":2,"currency":{ suffix: '%' }},model:{value:(_vm.config.rates[index]),callback:function ($$v) {_vm.$set(_vm.config.rates, index, $$v)},expression:"config.rates[index]"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)}}],null,true)})}),1),_c('CRow',{staticClass:"form-actions"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('PAYOUT_POLICY_PAGE_BUTTON_SAVE')))]):_vm._e()],1)],1)],1)]}}],null,false,95846695)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }