<template>
  <div class="update-membership-policy-container wrapper">
    <CRow class>
      <CCol lg="6" md="6">
        <CCard>
          <CCardHeader>
            <h1>{{$t('PAYOUT_POLICY_PAGE_TITLE')}}</h1>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider
                  name="PAYOUT_POLICY_PAGE_LABEL_COMMISSION"
                  rules="required|max_value:100|min_value:0"
                >
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="5" md="5">
                      <label>{{$t('PAYOUT_POLICY_PAGE_LABEL_COMMISSION')}}</label>
                    </CCol>
                    <CCol lg="7" md="7">
                      <currency-input
                        class="input-field form-control"
                        v-model="config.proportionShare"
                        :precision="2"
                        :currency="{ suffix: '%' }"
                      />

                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <ValidationProvider
                  name="PAYOUT_POLICY_PAGE_LABEL_MAX_LEVEL"
                  :rules="`required|min_value:1|max_value:${maxLevels}`"
                >
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="5" md="5">
                      <label>{{$t('PAYOUT_POLICY_PAGE_LABEL_MAX_LEVEL')}}</label>
                    </CCol>
                    <CCol lg="7" md="7">
                      <input
                        type="text"
                        v-model="config.maxLevels"
                        class="form-control"
                        @change="onChangeMaxLevel()"
                      />
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <div class="form-max-rate">
                  <ValidationProvider
                    v-for="(rate, index) in config.rates"
                    :key="index"
                    :name="`${$t('PAYOUT_POLICY_PAGE_LABEL_LEVEL')} ${index + 1}`"
                    rules="required|max_value:100|min_value:0"
                  >
                    <CRow class="form-group" slot-scope="{ errors }" :key="index">
                      <CCol lg="5" md="5">
                        <label>{{$t('PAYOUT_POLICY_PAGE_LABEL_LEVEL')}} {{index + 1}}</label>
                      </CCol>
                      <CCol lg="7" md="7">
                        <currency-input
                          class="input-field form-control"
                          v-model="config.rates[index]"
                          :precision="2"
                          :currency="{ suffix: '%' }"
                        />

                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </div>

                <CRow class="form-actions">
                  <CCol lg="12" md="12">
                    <CButton
                      v-if="isAllowedUpdating"
                      color="primary"
                      class="btn btn-primary"
                      @click="onSubmit"
                    >{{$t('PAYOUT_POLICY_PAGE_BUTTON_SAVE')}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus } from '@/enums';
import { env } from '@/constants';

export default {
  name: 'MembershipPolicy',
  components: {
    VclList,
  },
  data() {
    return {
      config: {
        proportionShare: null,
        maxLevels: 1,
        rates: [],
      },
      isLoading: true,
      isProcessing: false,
      membershipPolicy: null,
      isAllowedUpdating: false,
      maxLevels: env.policy.maxLevelsOfMembershipPolicy,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    this.getMembershipPolicy();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_MEMBERSHIP_POLICY') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getMembershipPolicy() {
      try {
        const result = await this.$http.get(endpoints.getPolicies);
        const membershipPolicy = (this.membershipPolicy = result.data.items.find(x => x.type.toUpperCase() === 'AFFILIATE'));
        this.config.proportionShare = Number(membershipPolicy.proportion_share);
        this.config.maxLevels = membershipPolicy.max_levels;
        this.config.rates = membershipPolicy.rates.map(x => Number(x));

        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onChangeMaxLevel() {
      const maxLevels = this.config.maxLevels ? Number(this.config.maxLevels) : null;

      if (maxLevels > 0 && maxLevels <= this.maxLevels) {
        if (this.config.rates.length > maxLevels) {
          this.config.rates = _.slice(this.config.rates, 0, maxLevels);
        } else if (this.config.rates.length < maxLevels) {
          do {
            this.config.rates.push(0);
          } while (this.config.rates.length < maxLevels);
        }
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.updateMembershipPolicy();
      });
    },
    async updateMembershipPolicy() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const { membershipPolicy } = this;
        const data = {
          name: membershipPolicy.name,
          description: membershipPolicy.description,
          proportion_share: this.config.proportionShare,
          max_levels: this.config.maxLevels,
          rates: [...this.config.rates],
        };
        const result = await this.$http.put(endpoints.updatePolicy(membershipPolicy.id), data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Policy has been updated',
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-membership-policy-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
    > .card-body{
      padding-top: 35px;
      h2 {
        color: #657187;
        font-size: 16px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }

      .form-group {
        margin: 0 0 21px 0;
        div{
          padding: 0;
          &.col-md-5{
            text-align: right;
            @media only screen and (max-width: 900px){
              text-align: left;
            }
            label{
              font-size: 14px;
              font-weight: 300;
              color: #3E4B65;
              line-height: 34px;
              padding-right: 10px;
            }
          }
          input{
            width: 100px;
            font-size: 14px;
            font-weight: 500;
            color: #3A4A7F;
            @media only screen and (max-width: 900px){
              width: 100%;
            }
          }
        }

      }

      .form-actions {
        text-align: center;
        padding-top: 15px;
        .btn {
          border: none;
          border-radius: 4px;
          width: 145px;
          height: 35px;
          padding: 0 30px;
          color: #fff;
          margin-bottom: 55px;
          &.btn-primary {
            background: #3B4B7E;
          }
        }
      }

      .form-max-rate{
        position: relative;
        padding-top: 25px;
        &::before{
          content: '';
          position: absolute;
          width: calc(100% + 40px);
          height: 1px;
          top: 0;
          left: -20px;
          background: #e4e5e7
        }
      }
    }
  }
}
</style>
